/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import './src/styles/global.css';

const initializeMatomo = () => {
  var _paq = (window._paq = window._paq || []);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u = '//matomo.treet.fi/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '2']);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
};

const handleAcceptCookie = () => {
  initializeMatomo();
};

export const onRouteUpdate = ({ location }) => {
  const hasConsent = getCookieConsentValue();

  if (hasConsent === 'true' && typeof window._paq !== 'undefined') {
    window._paq.push(['setCustomUrl', location.pathname]);
    window._paq.push(['trackPageView']);
  }
};

const RootWrapper = ({ element }) => {
  useEffect(() => {
    const hasConsent = getCookieConsentValue();
    if (hasConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <>
      {element}
      <Box width="66%">
        <CookieConsent
          enableDeclineButton
          declineButtonText="Hylkää"
          buttonText="Hyväksy"
          onAccept={handleAcceptCookie}
          expires={365}
          style={{ background: 'rgb(232, 244, 253)', color: '#202944', fontFamily: 'Cera Round Pro' }}
          declineButtonStyle={{ backgroundColor: 'grey', fontFamily: 'Cera Round Pro' }}
          buttonStyle={{ backgroundColor: '#60D068', fontWeight: 'bold', fontFamily: 'Cera Round Pro' }}
        >
          <Typography component="p">
            Valitsemalla “Hyväksy” hyväksyt evästeiden tallentamisen laitteellesi, jotta voimme parantaa sivuston
            toimivuutta, analysoida sivuston käyttöä ja toteuttaa markkinointia.
          </Typography>
        </CookieConsent>
      </Box>
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return <RootWrapper element={element} />;
};
