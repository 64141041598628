// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---markdown-news-templates-newspost-template-tsx": () => import("./../../../markdown-news/templates/newspost_template.tsx" /* webpackChunkName: "component---markdown-news-templates-newspost-template-tsx" */),
  "component---src-pages-ajankohtaista-tsx": () => import("./../../../src/pages/ajankohtaista.tsx" /* webpackChunkName: "component---src-pages-ajankohtaista-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meista-tsx": () => import("./../../../src/pages/meista.tsx" /* webpackChunkName: "component---src-pages-meista-tsx" */),
  "component---src-pages-ohjeet-tsx": () => import("./../../../src/pages/ohjeet.tsx" /* webpackChunkName: "component---src-pages-ohjeet-tsx" */)
}

